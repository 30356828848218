import { Flex, Heading, Input, Text } from "@chakra-ui/react";
import * as React from "react";
import { DefaultButton, Layout, Seo } from "../components";
import "../styles/screen.scss";

const seoBundle = {
  title: "Boise Steel Erectors",
  subtitle: "Contact JMF Steel Erectors",
  description: "Contact us for steel erector buildings",
  slug: "/contact",
};

const ContactPage = ({ location }) => {
  const copy = () => {
    /* Get the text field */
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied!";
  };

  const outFunc = () => {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy to clipboard";
  };

  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Flex
        maxW='full'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        bg={"ltOrange"}
        align='center'
        justify='center'
        flexDirection='column'
      >
        <Heading
          as='h1'
          color='dkGrey'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='heading'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          Contact
        </Heading>
        <Text
          mt='20px'
          mb='7'
          textAlign='center'
          fontSize={20}
          maxW='800px'
          lineHeight='120%'
        >
          Have a question? Feel free to reach out.
        </Text>
        <Flex flexDir='column' align='center'>
          <Text
            mt='20px'
            mb='7'
            className='cap'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            Thank you for your interest in JMF Construction, Boise's leading
            steel construction company. We are here to assist you with any
            inquiries, project ideas, or concerns you may have. We look forward
            to discussing your steel construction needs and working with you to
            bring your project to life.
          </Text>
          <Flex id='contact-info' flexDir='column'>
            <Heading as='h4' fontWeight={500} fontSize='18px' pt='40px'>
              Phone:{" "}
              <a className='contact-link' href='tel:2088801062'>
                <Text as='span' fontWeight={700}>
                  (208) 880-1062
                </Text>
              </a>
            </Heading>
            <Heading
              id='email-heading'
              display='flex'
              flexDir='row'
              justifyContent='center'
              alignItems='center'
              as='h4'
              fontWeight={500}
              fontSize='18px'
              mb='60px'
              mt='20px'
            >
              Email:{" "}
              <Input
                h='40px'
                className='contact-input'
                type='text'
                value='info@jmfidaho.com'
                id='myInput'
                w='100%'
                ml='5px'
                mr='5px'
                mt={["5px", null, null]}
                minW={(null, null, "200px")}
                fontSize='16px'
              ></Input>
              <div class='tools'>
                <DefaultButton
                  onClick={() => copy()}
                  onMouseOut={() => outFunc()}
                  title='Copy Text'
                >
                  <span class='tooltiptext' id='myTooltip'>
                    Copy to clipboard
                  </span>
                </DefaultButton>
              </div>
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default ContactPage;
